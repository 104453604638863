<template>
    <b-overlay :show='showLoader'>
        <b-card title="Import promotional items from excel">
            <b-row>
                <b-col>
                    <b-alert show class="p-1" variant="warning">Select promotional item type. All imported promotional items will be of this type!</b-alert>
                    <label for="promotional_item_type">Promotional item type</label>
                    <v-select
                        id="promotional_item_type"
                        v-model="selectedPromotionalItemType"
                        label="name"
                        :options="promotionalItemTypes"
                    />
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <b-form-file v-model="file" accept=".xlsx"></b-form-file>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <b-button @click="uploadExcelFile">Upload excel</b-button>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BFormFile, BButton, BAlert} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {BCol, BRow, BOverlay, BCard, BFormFile, BButton, BAlert, vSelect},
        data() {
            return {
                showLoader: false,
                file: null,
                promotionalItemTypes: [],
                selectedPromotionalItemType: null
            }
        },
        methods: {
            uploadExcelFile() {
                if (!this.selectedPromotionalItemType) {
                    this.$printWarning('Please select promotional item type!')
                    return
                }

                if (!this.file) {
                    this.$printWarning('Please select excel file')
                    return
                }

                const thisIns = this
                thisIns.showLoader = true

                const formData = new FormData()
                formData.append('file', this.file)

                thisIns.$http.post(`/api/management/v1/promotional_item/excelImport?promotional_item_type_id=${this.selectedPromotionalItemType.id}`, 
                                   formData, 
                                   {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(() => {
                        thisIns.showLoader = false
                        thisIns.$printSuccess('Promotional items successfuly imported')
                        thisIns.$router.go(-1)
                    })
                    .catch(error => {
                        thisIns.$printError((error.response) ? error.response.data : error)
                        thisIns.showLoader = false
                    })
            },
            loadData() {
                const thisIns = this
                this.showLoader = true
                const promotionalItemTypesPromise = this.$http.get('/api/management/v1/promotional_item_type')
                promotionalItemTypesPromise.then(function(response) {
                    thisIns.promotionalItemTypes = response.data
                    if (thisIns.promotionalItemTypes.length > 0) thisIns.selectedPromotionalItemType = thisIns.promotionalItemTypes[0]
                    thisIns.showLoader = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                    thisIns.showLoader = false
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>